const sectionNegocios = document.querySelector("#form-negocios");
const sectionVagas = document.querySelector("#form-vagas");

function mask(o, f) {
  setTimeout(function() {
    var v = mphone(o.value);
    if (v != o.value) {
      o.value = v;
    }
  }, 1);
}

function mphone(v) {
  var r = v.replace(/\D/g, "");
  r = r.replace(/^0/, "");
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (r.length > 5) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
  } else {
    r = r.replace(/^(\d*)/, "($1");
  }
  return r;
}

function retiraInvalidField(form) {
  let inputs = form.querySelectorAll("input, textarea");
  for (let i = 0; i < inputs.length; i++) {
    inputs[i].addEventListener("change", (event) => {
      event.target.parentElement.classList.remove("invalid");
    });
  }
};

function validaForm(form) {
  let camposObrigatorios = form.querySelectorAll("[required]");
  let camposNaoPreenchidos = [];
  let alertMensagem = form.querySelector("#boxAlert");
  camposObrigatorios.forEach((input) => {
    if (input.value.trim() == "") camposNaoPreenchidos.push(input);
    if (input.classList.contains("telefone")) {
      if (input.value.length < 14) camposNaoPreenchidos.push(input);
    }
    if (input.type == "email") {
      let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(input.value)) camposNaoPreenchidos.push(input);
    }
    });
    if (camposNaoPreenchidos.length > 0) {
      camposNaoPreenchidos.forEach((input) => {
        input.parentNode.classList.add("invalid");
      });
      if (!form.querySelector(".alert__alert"))
      alertMensagem.classList.add("on");
    } else {
      alertMensagem.classList.remove("on");
      enviarForm(form);
    }
  }

  function enviarForm(form) {
    let mensagemContato = form.querySelector("#boxMensagem");
    let action = form.getAttribute("action");
    let requisicao = new XMLHttpRequest();
    let btn = form.querySelector('.cta');

      mensagemContato.innerHTML = "Enviando mensagem. Aguarde!";
    mensagemContato.classList.add("on");
    btn.setAttribute('disabled', '');

      let formData = new FormData(form);

      requisicao.open("POST", action);

    requisicao.onload = function () {
      if (this.status === 200) {
        mensagemContato.innerHTML = "Mensagem enviado com sucesso!";
        mensagemContato.classList.add("on");
        btn.removeAttribute('disabled');
        form.reset();
      } else {
        mensagemContato.innerHTML = "Erro ao enviar o formulário";
        mensagemContato.classList.add("on");
      }
    };

      requisicao.send(formData);
  }


if (sectionNegocios) {

  const btnNegocios = document.querySelectorAll(".btn-negocios");
  const formNegocios = sectionNegocios.querySelector("#negocios");
  btnNegocios.forEach((btn)=>{
    btn.addEventListener("click", ()=> setAssunto(btn));
  });

  function setAssunto(btn){
    let title = btn.dataset.assunto;
    let assunto = formNegocios.querySelector("#assunto");
    assunto.value = title;
  }

  let btn = formNegocios.querySelector(".cta");
  btn.addEventListener("click", (enviar) => {
    enviar.preventDefault();
    validaForm(formNegocios);
  });
  retiraInvalidField(formNegocios);

}


if (sectionVagas) {

  const btnVagas = document.querySelectorAll(".btn-vaga");
  const formVagas = sectionVagas.querySelector("#vagas");

  btnVagas.forEach((btn)=>{
    btn.addEventListener("click", ()=> setAssunto(btn));
  });

  function setAssunto(btn){
    let title = btn.dataset.vaga;
    let vaga = sectionVagas.querySelector("#vaga");
    vaga.value = title;
  }


  let btn = formVagas.querySelector("button");
  btn.addEventListener("click", (enviar) => {
    enviar.preventDefault();
    validaForm(formVagas);
  });
  retiraInvalidField(formVagas)
}