const sliders = document.querySelectorAll(".sliders");

if(sliders){

  sliders.forEach( slider =>{
    createSliders(slider);
  });

  function createSliders(slider){
    let data = slider.dataset.desktop;
    let content = slider.querySelector(".splide");

    let optionsSlidersMobile = {
      type      : 'loop',
      rewind    : true,
      pagination: false,
      arrows    : true,
      perPage: 1,
      perMove: 1,
      gap: 12,
      mediaQuery: 'min',
      breakpoints: {
        768: {
          destroy: true,
        },
      }
    };

    let optionsSlidersDesktop = {
      type      : 'loop',
      rewind    : true,
      pagination: false,
      arrows    : true,
      perPage: 3,
      perMove: 1,
      gap: 46,
      breakpoints: {
        480: {
          perPage: 1
        },
      }
    };

    let optionsSliders = (data) ? optionsSlidersDesktop : optionsSlidersMobile;

    const main = new Splide(content, optionsSliders );

    main.mount();
  }
}