const bigNumbers = document.querySelector(".about-us__numbers");

if (bigNumbers) {
  let hasStarted = false;

  const countUp = (span, count, number, increment) => {
    span.innerText = count.toLocaleString("pt-BR");

    if (count < number) {
      const nextCount = count + increment > number ? number : count + increment;
      setTimeout(() => countUp(span, nextCount, number, increment), 30);
    }
  };

  const startCounting = () => {
    const rect = bigNumbers.getBoundingClientRect();

    if (rect.top >= 0 && rect.bottom <= window.innerHeight && !hasStarted) {
      hasStarted = true;

      const items = bigNumbers.querySelectorAll(".bigNumbers");

      items.forEach((item) => {
        let span = item.querySelector(".number");
        let number = parseInt(span.getAttribute("num"));

        let increment = Math.max(1, Math.floor(number / 100));
        countUp(span, 0, number, increment);
      });
    }
  };

  window.addEventListener("scroll", startCounting);
  window.addEventListener("load", startCounting);
}
