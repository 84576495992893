const headerBlock = document.getElementById("header");
const contentOff = (elms) => elms.forEach((el) => el.classList.remove("on"));

if (headerBlock) {

  (function openMenu() {
    let burguer = document.getElementById("burguer-menu");
    burguer.addEventListener("change", function () {
      if (burguer.checked == true) {
        document.querySelector("body").classList.add("lock-scroll");
      } else {
        document.querySelector("body").classList.remove("lock-scroll");
      }
    });
  })();

  (function menuIndicator() {
    const menu = headerBlock.querySelector(".menu-list");
    const botoes = menu.querySelectorAll("a");
    let hashs = [];
    for (let i = 0; i < botoes.length; i++) {
      hashs.push(botoes[i].hash);
    }
    hashs = hashs.filter(function (e) {
      return e != "";
    });

        botoes.forEach((btn) => {
      if (btn.href.includes("#")) {
        btn.addEventListener("click", toggleMenu);
      }
    });

    function toggleMenu() {
      if (window.innerWidth < 1024) {
        const burguer = document.getElementById("burguer-menu");
        const body = document.querySelector("body");

              burguer.checked = !burguer.checked;

              if (burguer.checked) {
          body.classList.add("lock-scroll");
        } else {
          body.classList.remove("lock-scroll");
        }
      }
    }

    function referenciaAnchor() {
      let scrollTop = document.documentElement.scrollTop || window.scrollY;
      for (let i = 0; i < hashs.length; i++) {
        let atual = document.querySelector(hashs[i]);
        if (atual) {
          let ref = atual.offsetTop;
          if (scrollTop + 200 > ref) {
            for (let j = 0; j < menu.querySelectorAll("a").length; j++) {
              menu.querySelectorAll("a")[j].parentNode.classList.remove("current_page_item");
            }
          }
        }
      }
    }

    window.addEventListener("scroll", referenciaAnchor);
    document.addEventListener("DOMContentLoaded", referenciaAnchor);
  })();


}